import type { SearchResult } from '@/src/types/searchQuery';

//
function productNameToUrl(productName: string): string {
    return productName.toLowerCase().replace(/[^a-z0-9]/g, '-');
}

/**
 * Redirect for Item Number, Product Number and Model Number Search to PDP
 *
 * 1. Number of search results returned  “numFound" in API response must be 1 AND satisfy one of the following criteria's
 * 2. Search term exactly matches “item_number” from API response
 *
 * Returns false to do nothing, and a string if a redirect should be done
 */
export function checkForItemNumberRedirect(
    keyword: string | string[],
    searchResult: SearchResult
): false | string {
    if (Array.isArray(keyword)) {
        keyword = keyword[0] as string;
    }
    if (searchResult.docs.length !== 1) {
        return false;
    }

    const item = searchResult.docs[0];

    if (item.item_number === keyword || item.group_id === keyword) {
        return `${productNameToUrl(item.item_product_name)}.product.${item.item_number}.html`;
    }

    return false;
}
