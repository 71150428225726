'use client';

import type { ReadonlyURLSearchParams } from 'next/navigation';

import React, {
    ReactNode,
    createContext,
    memo,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    CATEGORY_PAGE_ID_REGEX,
    CATEGORY_QUERY_APP,
    LW_AB_QUERY_PARAM,
    SEARCH_QUERY_APP,
} from '@/constants/index';
import {
    REFINEMENT_KEY_DELIVERY,
    REFINE_QUERY_DELIMITER,
} from '@/constants/index';
import { fullyDecodeURI } from '@/src/components/SearchResultsFacets/utils';
import type {
    ContentStackEntryDataProps,
    LocaleProps,
    SearchPageConfigProperties,
    SearchServiceConfig,
} from '@/src/types/contentStack';
import { SearchParams, SearchResult } from '@/src/types/searchQuery';
import {
    useBrowseContext,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

import { checkForItemNumberRedirect } from './providerUtils';
import type {
    LucidWorksContextType,
    WarehouseCookieValue,
    WarehouseSearchParams,
} from './types';
import {
    buildMultiSelectFacet,
    generateFacetFilterParam,
    getCurrentPage,
    getFirstItemFromPageNumber,
    getLWABValue,
    getLocationsFromCookie,
    getNumberOfPages,
    isAttrRefinement,
    isCategoryPath,
    isPriceRefinement,
    isProgramRefinement,
    isRatingRefinement,
    updatePageTitle,
} from './utils';

// Exported for unit testing
export async function buildSearchQuery(
    searchParams: ReadonlyURLSearchParams,
    lang: LocaleProps,
    properties: SearchPageConfigProperties,
    { loc, whloc, userLocation }: WarehouseSearchParams
): Promise<string> {
    let keyword = searchParams.get('keyword');

    let refine = fullyDecodeURI(searchParams.get('refine'));

    let currentPage = searchParams.get('currentPage') || 0;

    if (!properties) {
        return '';
    }

    const { baseUrl, resultsPerPage, searchApp, additionalURLParams } =
        properties;

    let facets = '';

    // "Delivery" filter is automatically applied unless opted out
    //deliveryFacetFlag=false item_program_eligibility-ShipIt
    if (!refine) {
        // If not explicitly opting out of delivery filter, apply it
        let deliveryFacetFlag = searchParams.get('deliveryFacetFlag');

        if (deliveryFacetFlag !== 'false') {
            refine = REFINEMENT_KEY_DELIVERY;
        }
    }

    if (refine) {
        const refinements = refine.split(REFINE_QUERY_DELIMITER);

        // Need to support multiple selections of price filters that are joined by OR
        const priceRefinements = refinements.filter(isPriceRefinement);

        if (priceRefinements.length > 0) {
            const priceRanges = priceRefinements.map(r => {
                const [, value] = r.split(/-(.*)/s);
                return value;
            });

            // We need custom formatting so the results will still include all possible Price ranges
            // fq={!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:([500 TO 1000])
            // Multiple ranges
            // fq={!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:([25 TO 50] OR [100 TO 200])
            // facets += `&fq=${encodeURIComponent(`{!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:(${value})`)}`;
            facets += `&fq=${encodeURIComponent(`{!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:(${priceRanges.join(' OR ')})`)}`;
        }

        // Attribute refinements support multiselect, need all values to come back and apply multiple of the same filter
        const attrRefinements = refinements.filter(isAttrRefinement);

        if (attrRefinements.length) {
            facets += buildMultiSelectFacet(attrRefinements);
        }

        // Rating refinements support multiselect
        const ratingRefinements = refinements.filter(isRatingRefinement);

        if (ratingRefinements.length) {
            facets += buildMultiSelectFacet(ratingRefinements);
        }

        const programRefinements = refinements.filter(isProgramRefinement);
        if (programRefinements.length) {
            facets += buildMultiSelectFacet(programRefinements);
        }

        const otherRefinements = refinements.filter(
            r =>
                !isPriceRefinement(r) &&
                !isAttrRefinement(r) &&
                !isRatingRefinement(r) &&
                !isProgramRefinement(r)
        );

        for (const refinement of otherRefinements) {
            const [key, value] = refinement.split(/-(.*)/s);

            if (!key || !value) {
                continue;
            }

            facets += generateFacetFilterParam(refinement);
        }
    }

    const firstItem = getFirstItemFromPageNumber(
        parseInt(currentPage.toString(), 10),
        resultsPerPage
    );

    let locale = lang;

    // LW is case sensitive, but all our langs are lowercase
    if (locale.includes('-')) {
        const langParts = lang.split('-');
        if (langParts.length === 2) {
            locale =
                `${langParts[0]!}-${langParts[1]!.toUpperCase()}` as LocaleProps;
        }
    }
    const sort = fullyDecodeURI(searchParams.get('sortBy'));

    const params = new URLSearchParams({
        [LW_AB_QUERY_PARAM]: getLWABValue(),
        q: keyword || '*',
        locale,
        start: firstItem.toString(),
        expand: 'false',
    });

    const optionalParams: { [key: string]: string | null | undefined } = {
        userLocation,
        loc,
        whloc,
    };

    for (const param in optionalParams) {
        if (optionalParams[param]) {
            params.set(param, `${optionalParams[param]}`);
        }
    }

    // Special case for loc not being set, default to star
    if (!loc) {
        params.set('loc', '*');
    }

    const isCategoryPage = isCategoryPath(window.location.pathname);

    const basePath = `/${searchApp}/query/${searchApp}_${isCategoryPage ? CATEGORY_QUERY_APP : SEARCH_QUERY_APP}`;

    if (isCategoryPage) {
        const [_match, pageId] =
            CATEGORY_PAGE_ID_REGEX.exec(window.location.pathname) || [];

        params.set('url', `/${pageId}.html`);
    }

    let url = `${baseUrl}${basePath}?${params.toString()}${facets}`;

    // URL encoding appears to break the sort on the LW side, so add it raw
    if (sort) {
        url += `&sort=${sort}`;
    }

    if (additionalURLParams) {
        url += `&${additionalURLParams}`;
    }

    return url;
}

async function doSearch(
    searchParams: ReadonlyURLSearchParams,
    lang: LocaleProps,
    requestHeaders: SearchServiceConfig['required_request_headers'],
    properties: SearchPageConfigProperties,
    { loc, whloc, userLocation }: WarehouseSearchParams,
    categoryPageId?: string
): Promise<SearchResult> {
    let returnValue: SearchResult = {
        isAdTargetingExeptionEnabled: false,
        isAdTargetingExplicitlyDisabled: false,
        isAdTargetingEnabled: false,
        redirect: '',
        docs: [],
        facets: [],
        variants: [],
        metrics: {
            original: undefined,
            correction: undefined,
            queryTime: 0,
            queryId: '',
            totalTime: 0,
            semantic: false,
            xFusionExitCode: '',
        },
        pagination: {
            currentPage: 0,
            totalDocs: 0,
            totalPages: 0,
        },
    };

    if (!properties) {
        return returnValue;
    }
    const { revalidateInMs, resultsPerPage } = properties;

    if (!searchParams.get('keyword') && !categoryPageId) {
        return returnValue;
    }

    const requestURL = await buildSearchQuery(searchParams, lang, properties, {
        loc,
        whloc,
        userLocation,
    });

    const response = await fetch(requestURL, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...requestHeaders,
        },
        next: {
            revalidate: revalidateInMs,
        },
    });

    if (!response.ok) {
        console.error('LW FETCH ERROR', response.statusText);
    }

    let data = await response.json();

    returnValue.pagination.currentPage = getCurrentPage(
        data.response.start,
        resultsPerPage
    );
    returnValue.pagination.totalDocs = data.response.numFound;
    returnValue.pagination.totalPages = getNumberOfPages(
        data.response.numFound,
        resultsPerPage
    );
    returnValue.docs = data.response.docs;
    returnValue.variants = data.expanded;
    returnValue.facets = data.facets ? Object.values(data.facets) : [];

    returnValue.isAdTargetingExeptionEnabled =
        data.fusion['X-Fusion-FSACHDI-Exception'] === '1';
    returnValue.isAdTargetingExplicitlyDisabled =
        data.fusion['X-Fusion-IncludesFSACHDI'] === '1';
    returnValue.isAdTargetingEnabled =
        returnValue.isAdTargetingExeptionEnabled ||
        !returnValue.isAdTargetingExplicitlyDisabled;

    returnValue.metrics.original = data.fusion['search.spelling.original'];
    returnValue.metrics.correction = data.fusion['search.spelling.corrected'];
    returnValue.metrics.queryTime = data.responseHeader.QTime;
    returnValue.metrics.queryId = data.fusion.fusionQueryId;
    returnValue.metrics.totalTime = data.responseHeader.totalTime;
    returnValue.metrics.semantic = !!data.fusion['search.related_results'];
    returnValue.metrics.xFusionExitCode =
        response.headers?.get('X-Fusion-ExitCode');

    returnValue.breadcrumb = data?.fusion?.breadcrumb;

    if (data?.fusion?.redirect) {
        returnValue.redirect = data.fusion.redirect[0];
    }

    returnValue.defaultFusionSort = data?.fusion?.requestedSort;

    return returnValue;
}

// Create the context with a default value
const LucidWorksContext = createContext<LucidWorksContextType | undefined>(
    undefined
);

type LucidWorksProviderProps = {
    lang: LocaleProps;
    config: ContentStackEntryDataProps;
    serviceConfig: SearchPageConfigProperties;
    children: ReactNode;
    requestHeaders: SearchServiceConfig['required_request_headers'];
    NoResultsComponent: ReactNode;
    loc?: string;
    whloc?: string;
    userLocation?: string;
    categoryPageId?: string;
    isEnabled?: boolean;
};

const LucidWorksProviderComponent = ({
    lang,
    serviceConfig,
    requestHeaders,
    children,
    NoResultsComponent,
    loc,
    whloc,
    userLocation,
    categoryPageId,
    isEnabled = true,
}: LucidWorksProviderProps) => {
    const { queryParams: searchParams } = useQueryParams();
    const [searchResult, setSearchResult] = useState<
        SearchResult | undefined
    >();
    const [searchedParams, setSearchedParams] = useState<
        SearchParams | undefined
    >();
    const [isSearchResultsLoading, setIsSearchResultsLoading] =
        useState<boolean>(isEnabled);

    useEffect(() => {
        const run = async () => {
            setIsSearchResultsLoading(true);
            try {
                const searchResult = await doSearch(
                    searchParams,
                    lang,
                    requestHeaders,
                    serviceConfig,
                    { loc, whloc, userLocation },
                    categoryPageId
                );

                const kw = searchParams.get('keyword')!;

                const redirect = checkForItemNumberRedirect(kw, searchResult);

                if (redirect) {
                    window.location.href = redirect;
                    return;
                }

                if (searchResult?.redirect) {
                    /* Bug 114623: in case of category page redirect, onclick of back button was not navigating user to page it came from */
                    // replace method of location is used so that it doesn't save the current request in the browser history
                    window.location.replace(searchResult.redirect);

                    return;
                }

                updatePageTitle(searchParams, searchResult, kw);

                setSearchResult(searchResult);
                setSearchedParams(Object.fromEntries(searchParams.entries()));
                setIsSearchResultsLoading(false);
            } catch (err) {
                console.error(err);
                setIsSearchResultsLoading(false);
            }
        };
        if (whloc && loc && isEnabled) {
            run();
        }
    }, [
        searchParams,
        lang,
        requestHeaders,
        serviceConfig,
        loc,
        whloc,
        userLocation,
        categoryPageId,
        isEnabled,
    ]);

    if (isEnabled === false) {
        return (
            <LucidWorksContext.Provider
                value={{ searchResult, isSearchResultsLoading, searchedParams }}
            >
                {children}
            </LucidWorksContext.Provider>
        );
    }

    return (
        <LucidWorksContext.Provider
            value={{ searchResult, isSearchResultsLoading, searchedParams }}
        >
            {isSearchResultsLoading ||
            (searchResult && searchResult.docs?.length > 0)
                ? children
                : NoResultsComponent}
            <div
                dangerouslySetInnerHTML={{
                    __html: `<!-- 
                        resultsLeadToFSACHDI: ${searchResult?.isAdTargetingExplicitlyDisabled ? 1 : 0}
                        isFSACHDIExceptionSet: ${searchResult?.isAdTargetingExeptionEnabled ? 1 : 0}
                        doNotTrackLayout: ${!searchResult?.isAdTargetingEnabled}
                    -->`,
                }}
            />
        </LucidWorksContext.Provider>
    );
};

const MemoizedLucidWorksProviderComponent = memo(LucidWorksProviderComponent);

// Create the provider component
export const LucidWorksProvider = (props: LucidWorksProviderProps) => {
    const { warehouse, deliveryLocation } = useBrowseContext();
    const { loc, whloc } = getLocationsFromCookie(
        warehouse as WarehouseCookieValue
    );
    const { state } = deliveryLocation || {};

    return (
        <MemoizedLucidWorksProviderComponent
            loc={loc}
            whloc={whloc}
            userLocation={state}
            {...props}
        />
    );
};

// Create a custom hook to use the LucidWorksContext
export const useLucidWorksContext = (): LucidWorksContextType => {
    const context = useContext(LucidWorksContext);
    if (context === undefined) {
        throw new Error(
            'useLucidWorksContext must be used within a LucidWorksProvider'
        );
    }
    return context;
};
