import { fullyDecodeURI } from '@/components/SearchResultsFacets/utils';
import { REFINE_QUERY_DELIMITER } from '@/constants/index';

export const getRatingsLabel = (rating: string): string => {
    return rating.replace('&', 'Stars &');
};

export function getSingleAttrValue(
    refinements?: string | string[] | null
): string {
    let value = '';

    if (!refinements) {
        return value;
    }

    const singleRefineParam = Array.isArray(refinements)
        ? refinements[0]
        : refinements;
    const refine = fullyDecodeURI(singleRefineParam)!;

    const attributes = refine
        .split(REFINE_QUERY_DELIMITER)
        .filter(facet => facet.includes('_attr'));

    if (attributes.length === 1 && attributes[0]) {
        const dashIndex = attributes[0].indexOf('-');
        value = attributes[0].slice(dashIndex + 1);
    }
    return value;
}
