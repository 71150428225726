import {
    CATEGORY_PATH_PREFIX,
    LW_AB_DEFAULT_VALUE,
    LW_AB_STORAGE_NAME,
} from '@/constants/index';
import {
    ITEM_LOCATION_PRICING_FACET_KEY,
    ITEM_PROGRAM_FACET_KEY,
    ITEM_RATING_FACET_KEY,
} from '@/constants/index';
import { SearchResult } from '@/src/types/searchQuery';
import { getCookie } from '@/utils/cookieUtils';
import { getSingleAttrValue } from '@/utils/searchfacets';

import type { WarehouseCookieValue } from './types';

/**
 * Take the index of the item returned from LW to calculate the current page
 * used in pagination navigation
 */
export function getCurrentPage(start: number, resultsPerPage: number) {
    if (start <= 0 || typeof start !== 'number') {
        return 1;
    }
    return Math.ceil(start / resultsPerPage) + 1;
}

export function getNumberOfPages(totalDocs: number, resultsPerPage: number) {
    return Math.ceil(totalDocs / resultsPerPage);
}

/**
 * Search URL includes a page parameter, but LW uses a start at item param,
 * so this maps from a page # to the first item on that page
 */
export function getFirstItemFromPageNumber(
    currentPage: number,
    resultsPerPage: number
) {
    if (currentPage <= 1 || typeof currentPage !== 'number') {
        return 0;
    }
    return currentPage * resultsPerPage - resultsPerPage;
}

export function getLocationsFromCookie(
    warehouseCookieValue: WarehouseCookieValue
) {
    const defaultReturnValue = ['*'];

    if (!warehouseCookieValue) {
        return { loc: defaultReturnValue.join(','), whloc: '' };
    }
    try {
        let returnValues = [];

        if (warehouseCookieValue.groceryCenters) {
            returnValues.push(...warehouseCookieValue.groceryCenters);
        }
        if (warehouseCookieValue.nearestWarehouse?.catalog) {
            returnValues.push(warehouseCookieValue.nearestWarehouse.catalog);
        }

        if (warehouseCookieValue.distributionCenters) {
            returnValues.push(...warehouseCookieValue.distributionCenters);
        }

        // If we don't have a distribution centers, the other centers don't
        // give us any value, so default to all
        if (
            !warehouseCookieValue.distributionCenters ||
            !warehouseCookieValue.distributionCenters.length
        ) {
            returnValues = defaultReturnValue;
        }

        return {
            loc: returnValues.join(','),
            whloc: warehouseCookieValue?.nearestWarehouse?.catalog || '',
        };
    } catch (e) {
        console.error(e);
        return { loc: defaultReturnValue.join(','), whloc: '' };
    }
}

/**
 * Used to filter out price refinements during search query building as they
 * need custom treatment to include multiple ranges
 */
export function isPriceRefinement(refinement: string) {
    return refinement.toLowerCase().startsWith(ITEM_LOCATION_PRICING_FACET_KEY);
}

// fq={!tag=<facet key>}<facet key>:("<filter value>")
export function generateFacetFilterParam(refinement: string) {
    const [key, value] = refinement.split(/-(.*)/s);

    return `&fq=${key}:("${encodeURIComponent(value!)}")`;
}

export function isAttrRefinement(refinement: string) {
    const [facetKey] = refinement.split(/-(.*)/s);
    if (!facetKey) {
        return false;
    }
    return facetKey.toLowerCase().endsWith('_attr');
}

export function isRatingRefinement(refinement: string) {
    return refinement.toLowerCase().startsWith(ITEM_RATING_FACET_KEY);
}

export function isProgramRefinement(refinement: string) {
    return refinement.toLowerCase().startsWith(ITEM_PROGRAM_FACET_KEY);
}

export function buildMultiSelectFacet(refinements: string[]): string {
    // Build a mapping of all values for each facet key
    let attrs: { [key: string]: string[] } = {};

    let facets = '';

    for (const attr of refinements) {
        const [facetKey, val] = attr.split(/-(.*)/s);
        if (!facetKey || !val) {
            continue;
        }

        if (!attrs[facetKey]) {
            attrs[facetKey] = [];
        }
        attrs[facetKey]!.push(val);
    }

    // Apply collected facet keys to URL
    for (const facetKey in attrs) {
        facets += `&fq=${encodeURIComponent(`{!tag=${facetKey}}${facetKey}:("${attrs[facetKey]!.join('" OR "')}")`)}`;
    }

    return facets;
}

export function getLWABValue() {
    try {
        return (
            localStorage.getItem(LW_AB_STORAGE_NAME) ||
            getCookie(LW_AB_STORAGE_NAME) ||
            LW_AB_DEFAULT_VALUE
        );
    } catch (e) {
        return LW_AB_DEFAULT_VALUE;
    }
}

export function appendCategoryUrl(url: string) {
    return `/c${url}`;
}

// Update page title with refinements
// If we have a single attribute param, add it to the page title
export function updatePageTitle(
    searchParams: URLSearchParams,
    searchResult: SearchResult,
    kw?: string
) {
    if (searchParams.get('refine')) {
        const value = getSingleAttrValue(searchParams.get('refine'));

        document.title = `${value} ${kw} | Costco`;
    } else if (kw) {
        document.title = `${kw} | Costco`;
    } else if (searchResult.breadcrumb) {
        const lastBreadcrumb = searchResult.breadcrumb.slice(-1)?.[0]?.name;
        document.title = `${lastBreadcrumb} | Costco`;
    } else {
        document.title = `Search | Costco`;
    }
}

export function isCategoryPath(path: string) {
    return path.startsWith(CATEGORY_PATH_PREFIX);
}
