export const ContentType = {
    AdBuilder: 'ad_builder',
    AdSet3rdParty: 'ad_set_3rd_party',
    AdSetCostco: 'ad_set_costco',
    AnnouncementBanner: 'announcement_banner',
    Category: 'category',
    ConfigurationSetting: 'Configuration_Setting',
    CustomMarkdown: 'custom_markdown',
    FeatureHighlightCard: 'feature_highlight_card',
    FeatureToggle: 'Feature_Toggle',
    Footer: 'footer_scaffold',
    Header: 'header_scaffold',
    HomePage: 'homepage',
    LinkListSimple: 'link_list_simple',
    LinkListWithFlyout: 'link_list_with_flyout',
    ModuleSettings: '_module_settings',
    PageSettings: '_page_settings',
    ServiceConfiguration: 'Service_Configuration',
    SiteSettings: '_site_settings',
    SystemDefinedLink: 'system_defined_link',
} as const;

export const DEFAULT_FIELD_EXCEPTIONS = [
    '_in_progress',
    '_metadata',
    'ACL',
    'created_by',
    'created_at',
    'tags',
    'updated_by',
    'updated_at',
    'publish_details',
];
