import type { ReadonlyURLSearchParams } from 'next/navigation';



import { FILTER_TYPE_LOCATION, FILTER_TYPE_PRICE, FILTER_TYPE_REVIEWS, SHOP_BY_PREFIX } from '@/components/Analytics/constants';
import { ITEM_PROGRAM_FACET_KEY } from '@/constants/index';
import { REFINEMENT_KEY_DELIVERY, REFINEMENT_KEY_IN_STOCK } from '@/constants/index';
import { REFINE_QUERY_DELIMITER } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import type { Facet, SearchResult } from '@/types/searchQuery';
import { getRatingsLabel } from '@/utils/searchfacets';



import { BUY_IN_WAREHOUSE_TEXT_KEY, CURRENT_PAGE, DELIVERY_BUCKET_VALUE, DELIVERY_TEXT_KEY, IN_WAREHOUSE_BUCKET_VALUE, ITEM_CATEGORY_FACET_KEY, ITEM_LOCATION_BOPIW, ITEM_LOCATION_FACET_KEY, ITEM_LOCATION_PRICING_FACET_KEY, ITEM_RATING_FACET_KEY, OUT_OF_STOCK_BUCKET_VALUE, OUT_OF_STOCK_TEXT_KEY, PICK_UP_TEXT_KEY, REFINE_QUERY_PARAM, TWO_DAY_DELIVERY_BUCKET_VALUE, TWO_DAY_DELIVERY_TEXT_KEY } from './constants';


function isEncoded(uri?: string | null) {
    uri = uri || '';

    return uri !== decodeURIComponent(uri);
}

export function fullyDecodeURI(uri?: string | null) {
    if (!uri) return;

    while (isEncoded(uri)) {
        uri = decodeURIComponent(uri);
    }

    return uri;
}

export function getFacetLabel(
    bucketValue: string,
    config: ContentStackEntryDataProps
) {
    switch (bucketValue.toLowerCase()) {
        case DELIVERY_BUCKET_VALUE:
            return getKeyValue(DELIVERY_TEXT_KEY, config);
        case TWO_DAY_DELIVERY_BUCKET_VALUE:
            return getKeyValue(TWO_DAY_DELIVERY_TEXT_KEY, config);
        case OUT_OF_STOCK_BUCKET_VALUE:
            return getKeyValue(OUT_OF_STOCK_TEXT_KEY, config);
        case PICK_UP_TEXT_KEY:
            return getKeyValue(PICK_UP_TEXT_KEY, config);
        case IN_WAREHOUSE_BUCKET_VALUE:
            return getKeyValue(BUY_IN_WAREHOUSE_TEXT_KEY, config);
        default:
            return null;
    }
}

/**
 * Function to identify the filterType from the filterKey
 * @param filterKey
 * @returns filterType
 */
export function getFilterType(filterKey: string) {
    let filterType;
    if (filterKey.includes('_attr')) {
        filterType = filterKey.split('_attr')[0]?.replace('_', ' ');
    }
    return filterType ? SHOP_BY_PREFIX + filterType : '';
}

/**
 * Even though Lucidworks returns a display order, they are not in the order the business
 * wants them, so we need to remap the the top few groups.... :shrug:
 */
export function remapFacetDisplayOrder(facet: Facet) {
    if (facet.facetKey === ITEM_CATEGORY_FACET_KEY) {
        facet.display_order = 0;
    }
    if (facet.facetKey === ITEM_PROGRAM_FACET_KEY) {
        facet.display_order = 1;
    }
    if (facet.facetKey === ITEM_LOCATION_BOPIW) {
        facet.display_order = 3;
    }
    if (facet.facetKey === ITEM_LOCATION_FACET_KEY) {
        facet.display_order = 4;
    }
    return facet;
}

export function sortFacets(a: Facet, b: Facet) {
    return a.display_order - b.display_order;
}

export function parseRefineParamToLabels(
    refinementString: string | string[] | undefined,
    config: ContentStackEntryDataProps
) {
    if (!refinementString) {
        return [];
    }

    if (Array.isArray(refinementString)) {
        refinementString = refinementString[0] as string;
    }

    return refinementString.split(REFINE_QUERY_DELIMITER).map(refinement => {
        let [key, value] = refinement.split(/-(.*)/s);

        if (!key || !value) {
            return { label: '', filterType: '', refinement };
        }

        let label = value;
        let filterType = '';
        switch (key.toLowerCase()) {
            case ITEM_LOCATION_PRICING_FACET_KEY:
                label = value
                    .replace('[', '$')
                    .replace(' TO ', ' - $')
                    .replace(']', '');
                filterType = FILTER_TYPE_PRICE;
                break;
            case ITEM_CATEGORY_FACET_KEY:
                const nesting = value.split('|');
                label = nesting[nesting.length - 1]!;
                break;
            case ITEM_RATING_FACET_KEY:
                label = getRatingsLabel(value);
                filterType = FILTER_TYPE_REVIEWS;
                break;
            case ITEM_LOCATION_FACET_KEY:
                // In stock checkbox is inverse of value
                label = '';
                break;
            case ITEM_PROGRAM_FACET_KEY:
                label = getFacetLabel(value, config)!;
                filterType = FILTER_TYPE_LOCATION;
                break;
            case ITEM_LOCATION_BOPIW:
                label = getFacetLabel(PICK_UP_TEXT_KEY, config)!;
                filterType = FILTER_TYPE_LOCATION;
                break;
            default:
                label = value;
                filterType = getFilterType(key);
                break;
        }

        return { label, filterType, refinement };
    });
}

export function getSelectFacetsFromUrl(
    searchParams: URLSearchParams,
    config: ContentStackEntryDataProps,
    resultFacets: SearchResult['facets']
) {
    const refineQueryParam =
        fullyDecodeURI(searchParams.get(REFINE_QUERY_PARAM)) || '';
    const refinements = parseRefineParamToLabels(
        fullyDecodeURI(searchParams.get(REFINE_QUERY_PARAM)) || '',
        config
    );

    // Add filters that are opt-out
    // deliveryFacetFlag could either be omitted, or set true or false, if true, it will be added
    // via the normal parsing, so if we see it at all, do nothing
    if (!searchParams.has('refine')) {
        // Some results wont have the default filters applicable, make sure they are in the response
        if (checkIfFacetValueExists(resultFacets, REFINEMENT_KEY_DELIVERY)) {
            refinements.push({
                label: getFacetLabel(DELIVERY_BUCKET_VALUE, config)!,
                filterType: FILTER_TYPE_LOCATION,
                refinement: REFINEMENT_KEY_DELIVERY,
            });
        }
    }

    const inStockOnlyRefinement = refineQueryParam
        .split(REFINE_QUERY_DELIMITER)
        .find(refinment => refinment === REFINEMENT_KEY_IN_STOCK);

    // If not in stock only, add the show out of stock as long as it exits in the returned facets
    if (!inStockOnlyRefinement) {
        if (checkIfFacetValueExists(resultFacets, REFINEMENT_KEY_IN_STOCK)) {
            refinements.push({
                label: getFacetLabel('in stock', config)!,
                filterType: FILTER_TYPE_LOCATION,
                refinement: REFINEMENT_KEY_IN_STOCK,
            });
        }
    }
    return refinements.filter(({ label }) => label);
}

function checkIfFacetValueExists(facetList: Facet[], refinement: string) {
    const [facetKey, val] = refinement.split(/-(.*)/s);
    const facet = facetList.find(group => group.facetKey === facetKey);
    if (!facet) {
        return false;
    }

    if (facet.buckets.find(bucket => bucket.val === val)) {
        return true;
    }

    return false;
}

export function checkRefinementApplied(
    key: string,
    searchParams: ReadonlyURLSearchParams
) {
    // Delivery only and In Stock are applied if refinement param is not set
    if (!searchParams.has(REFINE_QUERY_PARAM)) {
        if (key === REFINEMENT_KEY_DELIVERY) {
            return true;
        }
        if (key === REFINEMENT_KEY_IN_STOCK) {
            return true;
        }
    }

    let refine = fullyDecodeURI(searchParams.get(REFINE_QUERY_PARAM));

    const inUrl = refine?.split(REFINE_QUERY_DELIMITER).includes(key);

    // If we have the "only in stock" filer, the checkbox should be unchecked
    if (key === REFINEMENT_KEY_IN_STOCK) {
        return !inUrl;
    }

    return inUrl;
}

export function updateSearchParams(
    updateSearchParamsProvider: (queryParams: ReadonlyURLSearchParams) => void,
    searchParams: ReadonlyURLSearchParams | URLSearchParams
) {
    const queryParams = new URLSearchParams(searchParams);

    // Use the history API to update the URL without reloading the page
    window.history.pushState(
        {},
        '',
        `${window.location.pathname}?${queryParams.toString()}`
    );
    window.scrollTo(0, 0);
    updateSearchParamsProvider(queryParams as ReadonlyURLSearchParams);
}

/**
 * Takes a facet key and value, adds or removes it, and returns the new query string
 */
export function updateRefinements(
    updateSearchParamsProvider: (queryParams: ReadonlyURLSearchParams) => void,
    refinementPair: string,
    isEnabled: boolean,
    urlParams: ReadonlyURLSearchParams
) {
    const queryParams = new URLSearchParams(urlParams);

    //remove pagination page counter when applying filters to avoid ending up no search results
    queryParams.delete(CURRENT_PAGE);

    let refinementArray =
        fullyDecodeURI(queryParams.get(REFINE_QUERY_PARAM))?.split(
            REFINE_QUERY_DELIMITER
        ) || [];

    // To help prevent duplicates, always filter out the current key
    refinementArray = refinementArray.filter(item => item !== refinementPair);

    // Some options are defaulted to on when there is no refinement array. So
    // if we are going from a state with no refinements to a state with refinements param,
    // we need to add the default refinements
    const defaultRefinements = [REFINEMENT_KEY_DELIVERY];

    // Now add the new one if it is enabled
    if (isEnabled && refinementPair !== REFINEMENT_KEY_IN_STOCK) {
        refinementArray.push(refinementPair);
    }

    // If turning off a defaulted refinement, add all the other ones
    // if there wasn't a previous refinement param, add the default ones
    // or they wont be applied
    if (
        !isEnabled &&
        defaultRefinements.includes(refinementPair) &&
        !queryParams.has(REFINE_QUERY_PARAM)
    ) {
        const otherDefaults = defaultRefinements.filter(
            r => r !== refinementPair
        );

        for (const refinement of otherDefaults) {
            if (!refinementArray.includes(refinement)) {
                refinementArray.push(refinement);
            }
        }
    }

    // If "removing" show out of stock, add in stock only
    if (!isEnabled && refinementPair === REFINEMENT_KEY_IN_STOCK) {
        refinementArray.push(refinementPair);
    }

    // If we're adding any other refinement, add all defaults
    if (
        !queryParams.has(REFINE_QUERY_PARAM) &&
        refinementPair !== REFINEMENT_KEY_DELIVERY
    ) {
        refinementArray.push(...defaultRefinements);
    }

    queryParams.set(
        REFINE_QUERY_PARAM,
        encodeURIComponent(refinementArray.join(REFINE_QUERY_DELIMITER))
    );

    // On refinement change, back to page one
    queryParams.delete('page');

    updateSearchParams(updateSearchParamsProvider, queryParams);
}

export function updatePageParam(
    updateSearchParamsProvider: (queryParams: ReadonlyURLSearchParams) => void,
    searchParams: ReadonlyURLSearchParams,
    pageNumber: string
) {
    const queryParams = new URLSearchParams(searchParams);
    queryParams.set('currentPage', pageNumber);
    updateSearchParams(updateSearchParamsProvider, queryParams);
}