const DEFAULT_DELIVERY_ZIP = 'na';
const DEFAULT_STORE_LOCATION = { storeLocation: { city: 'na' } };

export const getLocalWarehouseFromCookies = (cookies: any) => {
    const {
        invCheckPostalCode: deliveryZip = DEFAULT_DELIVERY_ZIP,
        STORELOCATION: storeLocation = DEFAULT_STORE_LOCATION,
    } = cookies;

    return { deliveryZip, localWH: storeLocation.storeLocation?.city };
};

export function getCookie(cookieName: string): string {
    try {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        if (typeof ca === 'undefined') {
            return '';
        }
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i] as string;
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    } catch (e) {
        return '';
    }
}
